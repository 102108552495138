<template>
  <div class="BackPerson backMenu">
    <div class="">
      <el-card class="box-card">
        <div class="MenuTop">
          <!-- <div class="hint-title">按钮名称：</div> -->
          <div class="sch">
            <el-input
              v-model="MenuApi.keywords"
              placeholder="账号/姓名"
              class="sch-input"
              clearable
            >
            </el-input>
            <el-select
              v-model="MenuApi.status"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="getadminser">查询</el-button>
          </div>

          <div class="btn">
            <el-button
              type="primary"
              @click="toAddBack"
              v-if="allJurisdiction.BackPerson113"
              >创建账号</el-button
            >
          </div>
        </div>
        <div class="MenuList scorr-roll">
          <el-table :data="adminList.data" border style="width: 100%">
            <el-table-column
              prop="date"
              label="序号"
              min-width="50"
              align="center"
            >
              <template slot-scope="{ row }">
                <!-- <div class="exceed">
                  {{ row.id }}
                </div> -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.id"
                  placement="top"
                >
                  <div class="exceed">{{ row.id }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="创建时间"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <!-- <div class="exceed">
                  {{ row.create_time || "/" }}
                </div> -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.create_time"
                  placement="top"
                >
                  <div class="exceed">{{ row.create_time }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="账号"
              min-width="100"
              align="center"
            >
              <template slot-scope="{ row }">
                <!-- <div class="exceed">
                  {{ row.name }}
                </div> -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.name"
                  placement="top"
                >
                  <div class="exceed">{{ row.name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="角色"
              min-width="100"
              align="center"
            >
              <template slot-scope="{ row }">
                <!-- <span class="exceed" v-for="item in row.role" :key="item.id">
                  {{ item.name }}
                </span> -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.roleList"
                  placement="top"
                >
                  <div class="exceed">{{ row.roleList }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              min-width="100"
              align="center"
            >
              <template slot-scope="{ row }">
                <!-- <div class="exceed">
                  {{ row.real_name }}
                </div> -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.real_name"
                  placement="top"
                >
                  <div class="exceed">{{ row.real_name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="手机号"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <!-- <div class="exceed">
                  {{ row.phone || "/" }}
                </div> -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.phone"
                  placement="top"
                >
                  <div class="exceed">{{ row.phone }}</div>
                </el-tooltip>
              </template> </el-table-column
            ><el-table-column
              prop="name"
              label="状态"
              min-width="80"
              align="center"
            >
              <template slot-scope="{ row }">
                <!-- <div class="exceed">
                  {{ row.status_string }}
                </div> -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.status_string"
                  placement="top"
                >
                  <div class="exceed">{{ row.status_string }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              width="350"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="rootBtn">
                  <button
                    @click="openDetails(row)"
                    v-if="allJurisdiction.BackPerson115"
                  >
                    详情
                  </button>
                  <button
                    @click="toBtn(row)"
                    v-if="
                      (row.can_edit && allJurisdiction.BackPerson116) ||
                      row.id == adminId
                    "
                  >
                    编辑
                  </button>
                  <button
                    @click="openReset(row)"
                    v-if="
                      allJurisdiction.BackPerson117 &&
                      row.id != adminId &&
                      row.can_edit
                    "
                  >
                    重置密码
                  </button>
                  <!-- <button
                    @click="openpopup(row, 1)"
                    style="color: #eb5e12"
                    v-if="
                      row.status == 0 &&
                      allJurisdiction.BackPerson118 &&
                      row.id != adminId &&
                      row.can_edit
                    "
                  >
                    启用
                  </button>
                  <button
                    @click="openpopup(row, 2)"
                    v-if="
                      row.status != 0 &&
                      allJurisdiction.BackPerson118 &&
                      row.id != adminId &&
                      row.can_edit
                    "
                  >
                    禁用
                  </button> -->
                  <el-popconfirm
                    :title="`确定要启用账号【${row.name}】？启用后账号将可进行登录，并根据相应权限进行操作。`"
                    v-if="
                      row.status == 0 &&
                      allJurisdiction.BackPerson118 &&
                      row.id != adminId &&
                      row.can_edit
                    "
                    @confirm="change(row)"
                  >
                    <el-button slot="reference" style="color: #eb5e12"
                      >启用</el-button
                    >
                  </el-popconfirm>
                  <el-popconfirm
                    :title="`确定要禁用账号【${row.name}】？禁用后账号不可进行登录操作。`"
                    v-if="
                      row.status != 0 &&
                      allJurisdiction.BackPerson118 &&
                      row.id != adminId &&
                      row.can_edit
                    "
                    @confirm="change(row)"
                  >
                    <el-button slot="reference">禁用</el-button>
                  </el-popconfirm>
                  <!-- <button
                    @click="openpopup(row, 3)"
                    v-if="
                      allJurisdiction.BackPerson114 &&
                      row.id != adminId &&
                      row.can_edit
                    "
                  >
                    删除
                  </button> -->
                  <el-popconfirm
                    :title="`确定要删除账号【${row.name}】？删除后账号不可进行登录操作且无法恢复，请谨慎操作。`"
                    @confirm="delAccount(row)"
                    v-if="
                      allJurisdiction.BackPerson114 &&
                      row.id != adminId &&
                      row.can_edit
                    "
                  >
                    <el-button slot="reference">删除</el-button>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="paging">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="10"
            layout="total,prev, pager, next, jumper"
            :total="adminList.total"
            :current-page="adminList.current_page"
          >
          </el-pagination>
        </div>
        <!-- 新建弹框 -->
        <div>
          <el-dialog
            :title="isAdd ? '新建账号' : '编辑账号'"
            :visible.sync="isAddBackPerson"
            width="600px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
          >
            <div class="AddMenu scorr-roll">
              <div class="AddMenu-1-box">
                <div>
                  <el-form label-width="120px">
                    <el-form-item label="账号:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.name"
                          placeholder="请输入账号"
                          class="sch-input"
                          maxlength="20"
                          :disabled="!isAdd"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="姓名:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.real_name"
                          placeholder="请输入姓名"
                          class="sch-input"
                          maxlength="20"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="手机号:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.phone"
                          maxlength="11"
                          placeholder="请输入手机号"
                          class="sch-input"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="密码" required v-if="isAdd">
                      <div class="inputbox">
                        <el-input
                          v-model="addData.password"
                          class="sch-input"
                          maxlength="32"
                          placeholder="请输入密码"
                          :type="!isPassword ? 'password' : 'text'"
                        ></el-input>
                        <div
                          class="left-ico right-ico"
                          @click="isPassword = !isPassword"
                        >
                          <img
                            v-if="isPassword"
                            src="@/assets/img/login-img/eye.png"
                            alt=""
                          />
                          <img
                            v-if="!isPassword"
                            src="@/assets/img/login-img/noeye.png"
                            alt=""
                          />
                        </div>
                        <span class="initial" @click="initial">初始密码</span>
                      </div>
                    </el-form-item>
                    <el-form-item label="角色选择:" required v-if="!isself">
                      <el-checkbox
                        v-model="item.check"
                        v-for="item in distributable"
                        :key="item.id"
                        :disabled="issuperadmin || item.is_super == '1'"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-form-item>
                  </el-form>
                  <div class="hint" v-if="!$route.query.steured && !isself">
                    <img src="@/assets/img/error.png" alt="" /> 温馨提示:
                    选中后即包含该角色下对应的权限
                  </div>
                </div>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeAddBackPerson()" class="call-off"
                >取 消</el-button
              >
              <el-button type="primary" @click="addAdmin" :disabled="isBtn"
                ><span v-if="isAdd">确认创建</span
                ><span v-else>保存</span></el-button
              >
            </span>
            <div class="bubble-box" v-if="vesicle">
              <div class="bubble">
                <i class="el-icon-warning"></i>
                放弃本次<span v-if="isAdd">创建</span
                ><span v-else>编辑</span>账号操作？
                <div>
                  <el-button class="btncance" @click="vesicle = false"
                    >取消</el-button
                  >
                  <el-button
                    class="affirmclose"
                    @click="isAddBackPerson = false"
                    >确认</el-button
                  >
                </div>
              </div>
            </div>
          </el-dialog>
          <el-dialog
            title="账号详情"
            :visible.sync="dialogDetails"
            width="20%"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
          >
            <div class="dialog-div">账号：{{ addData.name }}</div>
            <div class="dialog-div">姓名：{{ addData.real_name }}</div>
            <div class="dialog-div">手机号：{{ addData.phone }}</div>
            <div class="dialog-div">
              角色：<span
                v-for="item in addData.role"
                :key="item.id"
                class="role-list"
                >{{ item.name }}</span
              >
            </div>
            <div class="dialog-div">
              创建人：{{ addData.create_name || "/" }}
            </div>
            <div class="dialog-div">创建时间：{{ addData.create_time }}</div>
            <div class="dialog-div">
              最近编辑人：{{ addData.edit_name || "/" }}
            </div>
            <div class="dialog-div">
              最近编辑时间：{{ addData.update_time }}
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogDetails = false"
                >关闭</el-button
              >
            </span>
          </el-dialog>
          <el-dialog
            title="重置密码提醒"
            :visible.sync="dialogReset"
            width="25%"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
          >
            确定要将当前账号【{{ Reset.name }}】重置为初始密码：“a123456”？
            <span slot="footer" class="dialog-footer">
              <el-button
                type="primary"
                @click="dialogReset = false"
                class="call-off"
                >关闭</el-button
              ><el-button type="primary" @click="confirmReset">重置</el-button>
            </span>
          </el-dialog>
        </div>
      </el-card>
    </div>
    <el-dialog :title="popup.title" :visible.sync="dialogVisible" width="20%">
      <span>{{ popup.mess }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" class="abolish"
          >取 消</el-button
        >
        <el-button type="primary" @click="change()" v-if="popup.type == '2'"
          >禁 用</el-button
        >
        <el-button type="primary" @click="change()" v-if="popup.type == '1'"
          >启 用</el-button
        >
        <el-button type="primary" @click="delAccount()" v-if="popup.type == '3'"
          >删 除</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapMutations, mapState } = createNamespacedHelpers("power");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      adminList: [],
      MenuApi: {
        keywords: "",
        page: 1,
        status: "",
      },
      isAddBackPerson: false, //弹框
      addData: {},
      stairOptions: [],
      isBtn: false,
      typeOptions: [
        {
          value: "0",
          label: "禁用",
        },
        {
          value: "1",
          label: "正常",
        },
      ],
      dialogDetails: false, //账号详情弹出框
      dialogReset: false, //重置密码提醒弹出框
      Reset: {}, //重置密码用户名
      distributable: [], //可分配权限列表
      isAdd: true,
      idDetails: {},
      adminId: 0,
      vesicle: false, //气泡
      oldmess: {},
      issuperadmin: false,
      dialogVisible: false,
      popup: {},
      isPassword: false,
      isself: false,
    };
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction", "detailsSeach"]),
    ...mapState(["BackPersonPage"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.MenuApi = this.detailsSeach[router];
    }
    this.getadmin();
    this.getDistributable();
    this.getadminid();
  },
  methods: {
    ...mapActions([
      "getadminList",
      "postregister",
      "getroleList",
      "getCanDistributionRole",
      "postv2register",
      "postedit",
      "getedit",
      "postAdminDelete",
    ]),
    ...mapMutations(["cgBackPersonPage"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    getadminser() {
      this.MenuApi.page = 1;
      this.getadmin();
    },
    openpopup(row, type) {
      this.popup = row;
      this.popup.type = type;
      if (type == 1) {
        this.popup.title = "启用账号提醒";
        this.popup.mess = `确定要启用账号【${row.name}】？启用后账号将可进行登录，并根据相应权限进行操作。`;
      }
      if (type == 2) {
        this.popup.title = "禁用账号提醒";
        this.popup.mess = `确定要禁用账号【${row.name}】？禁用后账号不可进行登录操作。`;
      }
      if (type == 3) {
        this.popup.title = "删除账号提醒";
        this.popup.mess = `确定要删除账号【${row.name}】？删除后账号不可进行登录操作且无法恢复，请谨慎操作。`;
      }
      this.dialogVisible = true;
    },
    initial() {
      this.$set(this.addData, "password", "a123456");
    },
    errormes(val) {
      this.$message({
        message: val,
        type: "warning",
      });
      this.isBtn = false;
    },
    // 取消创建和编辑
    closeAddBackPerson() {
      if (this.isAdd) {
        let num = this.distributable.filter((v) => {
          return v.check;
        });
        if (
          (this.addData.name && this.addData.name != "") ||
          (this.addData.real_name && this.addData.real_name != "") ||
          (this.addData.phone && this.addData.phone != "") ||
          (this.addData.password && this.addData.password != "") ||
          num.length > 0
        ) {
          this.vesicle = true;
        } else {
          this.isAddBackPerson = false;
        }
      } else {
        let num = [];
        this.distributable.forEach((v) => {
          if (v.check) {
            num.push(v.id);
          }
        });
        let ischage =
          num.length === this.oldmess.ids.length &&
          num.sort().toString() === this.oldmess.ids.sort().toString();
        if (
          this.addData.name == this.oldmess.name &&
          this.addData.real_name == this.oldmess.real_name &&
          this.addData.phone == this.oldmess.phone &&
          (ischage || this.isself)
        ) {
          this.isAddBackPerson = false;
          // this.vesicle = true;
        } else {
          this.vesicle = true;
          // this.isAddBackPerson=false
        }
      }
    },
    getadminid() {
      this.adminId = sessionStorage.getItem("admin_id");
    },
    // 获取角色列表
    async getrole() {
      let data = await this.getroleList();
      this.stairOptions = data.data.data;
    },
    // 获取当前账号可分配的权限
    async getDistributable() {
      let data = await this.getCanDistributionRole();
      data.data.forEach((v) => {
        v.check = false;
      });
      this.distributable = data.data;
    },
    // 新建编辑后台人员
    async addAdmin() {
      this.isBtn = true;
      let data = {};
      this.addData.role_id = [];
      this.addData.role = [];
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/; //设置手机号正则表达式
      let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/; //必须包含字母数字正则
      let regex2 = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$*-+-~`%^&()]).{6,32}$/; //字母，数字，符号正则
      let regex3 = /^[A-Za-z0-9]{4,40}$/; //字母，数字，

      if (!this.addData.name || this.addData.name == "") {
        this.errormes("请输入账号");
        return;
      } else if (this.addData.name.length < 5) {
        this.errormes("请输入5~20位账号");
        return;
      } else if (!regex3.test(this.addData.name)) {
        this.errormes("账号只能为数字和字母");
        return;
      }
      if (!this.addData.real_name || this.addData.real_name == "") {
        this.errormes("请输入姓名");
        return;
      } else if (this.addData.real_name.length < 2) {
        this.errormes("请输入2~20位姓名");
        return;
      }
      if (!myreg.test(this.addData.phone)) {
        //验证手机号格式是否正确
        this.errormes("手机号格式不正确");
        return;
      }

      if (
        this.isAdd &&
        (!this.addData.password || this.addData.password == "")
      ) {
        this.errormes("密码不能为空");
        return;
      } else if (
        this.isAdd &&
        (this.addData.password.length < 6 || this.addData.password.length > 32)
      ) {
        this.errormes(
          "请输入6-32位字母、数字、符号组成的账号密码，设置密码时请区分大小写。"
        );
        return;
      }
      if (
        this.isAdd &&
        !regex2.test(this.addData.password) &&
        !regex.test(this.addData.password)
      ) {
        this.errormes(
          "请输入6-32位字母、数字、符号组成的账号密码，设置密码时请区分大小写。"
        );
        return;
      }
      this.distributable.forEach((v) => {
        if (v.check) {
          this.addData.role_id.push(v.id);
          this.addData.role.push(v.id);
        }
      });
      if (this.addData.role_id.length == 0 && !this.isself) {
        this.errormes("请为账号选择至少一个角色");
        return;
      }
      if (this.isAdd) {
        data = await this.postv2register(this.addData);
      } else {
        // delete this.addData.create_name;
        delete this.addData.creater_admin_id;
        delete this.addData.edit_name;
        delete this.addData.id;
        delete this.addData.update_time;
        delete this.addData.role_id;
        delete this.addData.create_time;
        delete this.addData.operator;
        delete this.addData.name;
        data = await this.postedit(this.addData);
      }
      this.isBtn = false;
      if (data.code == 200 && this.isAdd) {
        this.getadmin();
        this.isAddBackPerson = false;
        this.$message({
          message: `您已成功创建新的账号【${this.addData.name}】`,
          type: "success",
        });
      }
      if (data.code == 200 && !this.isAdd) {
        this.getadmin();
        this.isAddBackPerson = false;
        this.$message({
          message: "操作成功",
          type: "success",
        });
      }
      if (data.code != "200") {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 获取后台人员列表
    async getadmin() {
      let data = await this.getadminList({
        keywords: this.MenuApi.keywords,
        page: this.MenuApi.page,
        status: this.MenuApi.status,
      });

      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.MenuApi };
      save.router = router;
      this.changeSeach(save);

      data.data.data.forEach((v) => {
        v.id = v.id + "";
        let roleList = "";

        v.role.forEach((item) => {
          // this.confirmReset.
          roleList = roleList + " " + item.name;
        });
        this.$set(v, "roleList", roleList);
      });
      this.adminList = data.data;
    },
    // 去新建
    toAddBack() {
      this.addData = {};
      this.isself = false;
      this.issuperadmin = false;
      this.isAdd = true;
      this.distributable.forEach((v) => {
        v.check = false;
      });
      // this.getrole();
      this.isAddBackPerson = true;
      this.vesicle = false;
    },
    // 去编辑
    toBtn(data) {
      if (data.id == this.adminId) {
        this.isself = true;
      } else {
        this.isself = false;
      }
      this.distributable.forEach((v) => {
        v.check = false;
      });
      this.isAdd = false;
      this.addData = {
        admin_id: data.id,
      };
      this.geteditApi();
      this.isAddBackPerson = true;
      this.vesicle = false;
    },
    // 查询接口
    async geteditApi() {
      this.issuperadmin = false;
      let data = await this.getedit(this.addData);
      this.oldmess = { ...data.data };
      this.oldmess.ids = [];
      data.data.role.forEach((v) => {
        this.oldmess.ids.push(v.id);
        this.distributable.forEach((item) => {
          if (v.is_super == 1) {
            this.issuperadmin = true;
          }
          if (v.id == item.id) {
            item.check = true;
          }
        });
      });
      data.data.admin_id = data.data.id;
      this.addData = data.data;
    },
    // 详情
    openDetails(row) {
      this.dialogDetails = true;
      this.addData = {
        admin_id: row.id,
      };
      this.geteditApi();
    },
    // 重置密码
    openReset(row) {
      this.Reset = {
        name: row.name,
        admin_id: row.id,
        password: "a123456",
      };
      this.dialogReset = true;
    },
    async confirmReset() {
      delete this.Reset.name;
      let data = await this.postedit(this.Reset);
      if (data.code == "200") {
        this.dialogReset = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 启用/禁用
    async change(row) {
      let data = await this.postedit({
        admin_id: row.id,
        status: !row.status,
      });
      if (data.code == "200") {
        this.dialogReset = false;
        this.getadmin();
        this.dialogVisible = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 删除
    async delAccount(row) {
      let data = await this.postAdminDelete({ admin_id: row.id });
      if (data.code == "200") {
        if (this.MenuApi.page > 1 && this.adminList.data.length == 1) {
          this.MenuApi.page--;
        }
        this.getadmin();
        this.dialogVisible = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 翻页
    handleCurrentChange(e) {
      this.MenuApi.page = e;
      this.getadmin();
    },
  },
};
</script>
<style lang='scss' scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<style lang="scss" scoped>
.backMenu {
  width: 100%;
  .MenuTop {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .hint-title {
      font-size: 15px;
      font-weight: bold;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .MenuList {
    margin-top: 20px;
    width: 100%;
    max-height: 65vh;
    .exceed {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rootBtn {
      button {
        font-size: 14px;
        border: none;
        background: none;
        cursor: pointer;
        color: #06b7ae;
        margin-left: 0;
      }
    }
  }
  .paging {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
  .AddMenu {
    width: 100%;
    max-height: 500px;
    .AddMenu-1-box {
      display: flex;
      justify-content: center;
      .inputbox {
        width: 400px;
        display: flex;
        .left-ico {
          position: relative;
          left: -60px;
          top: 10px;
        }
      }
    }
  }
}
.sch {
  display: flex;
}
.sch-input {
  width: 220px;
  height: 32px;
  margin-right: 20px;
}
button {
  padding: 0 12px;
  background-color: #06b7ae;
  color: #fff;
  border: none;
  height: 32px;
  margin-left: 20px;
  line-height: 100%;
}
.hint {
  color: #f53f3f;
  margin-left: 120px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}
.dialog-div {
  line-height: 32px;
  // height: 32px;
  color: #333;
  font-size: 14px;
  // overflow: auto;
  span {
    line-height: 28px;
    height: 28px;
    display: inline-block;
    background-color: #fff7e8;
    color: #ff7d00;
    padding: 0 8px;
    border-radius: 4px;
  }
}
.role-list {
  margin-right: 5px;
}
// ::v-deep .el-input{
//   width: 200px;
//   height: 32px;
//   margin-right: 20px;
// }
.bubble-box {
  position: relative;
  .bubble {
    position: absolute;
    right: 0;
    bottom: -150px;
    background-color: #fff7e8;
    padding: 10px;
    border-radius: 4px;
    i {
      color: #ff7d00;
    }
  }
}
.left-ico {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    height: 20px;
  }
}
.cancel {
  background-color: #fff7e8;
  color: #333;
  margin-top: 5px;
  height: 20px;
}
.call-off {
  background-color: #f5f5f5;
  color: #383b46;
}
.affirmclose {
  margin-top: 5px;
  height: 20px;
}
.dialog-footer {
  button {
    padding: 10px 26px;
  }
}
.abolish {
  background-color: rgba($color: #2e3238, $alpha: 0.05);
  color: #383b46;
}

.initial {
  font-size: 12px;
  color: #06b7ae;
  cursor: pointer;
}
::v-deep .el-input--suffix {
  height: 32px;
}
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  background-color: #f2f3f5 !important;
}
::v-deep .el-table--border {
  border-radius: 10px;
  overflow: hidden;
}
::v-deep .el-checkbox__label {
  color: #383b46;
}
::v-deep .el-dialog__body {
  padding: 0 43px 20px;
}
::v-deep .el-dialog__headerbtn {
  display: none;
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
// ::v-deep .el-input__inner{
//   border-radius: 4px !;
// }
</style>
